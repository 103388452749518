const Title = ({ room, questionStep, displayWebcam, blob}) => {
    if (displayWebcam || blob) {
        return (
            <h3 className="text-xl	font-bold mb-4 text-pink-whyme">
                Question {questionStep} : {room.questions[questionStep - 1].title}
            </h3>
        );
    }

    return (
        <h3 className="text-2xl	font-bold mb-4">
            &nbsp;
        </h3>
    );
}

export default Title;
