import {useCallback, useEffect, useRef, useState} from "react";
import WebcamComponent from "../../components/step6/WebcamComponent";
import Title from "../../components/step6/Title";
import CenterContent from "../../components/step6/CenterContent";
import Button from "../../components/step6/Button";
import FinalStep from "../../components/final_step";
import RecIcon from "../../assets/rec.gif";
import ChronoIcon from "../../assets/chrono_icon.png";
import FooterSite from "../../components/footerSite";
import axios from "axios";
import LoaderFinal from "../../components/loader_final";

const Step6 = ({ room, setActualStep }) => {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [blob, setBlob] = useState(null);
    const [blobs, setBlobs] = useState([]);

    const [questionStep, setQuestionStep] = useState(0);
    const [question, setQuestion] = useState(1);

    const [displayWebcam, setDisplayWebcam] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);

    const [counterDisplayQuestion, setCounterDisplayQuestion] = useState(room.time_question);
    const [counterTampon, setCounterTampon] = useState(1);
    const [counterRecordingResponse, setCounterRecordingResponse] = useState(room.time_response);

    const [tries, setTries] = useState(2);

    const [stopBeforeEnd, setStopBeforeEnd] = useState(false);

    const [finalStep, setFinalStep] = useState(false);

    const [finalLoader, setFinalLoader] = useState(false);

    let timeoutResponse = null;

    useEffect(() => {
        setRecordedChunks([]);
    }, []);

    const tryAgain = () => {
        setBlob(null);
        setTries(tries - 1);
        setRecordedChunks([]);
        setCounterDisplayQuestion(room.time_question);
        setCounterRecordingResponse(room.time_response);
        setCounterTampon(1);
        setStopBeforeEnd(false);
    }

    const cleanData = async(data) => {
        const formData = new FormData();
        formData.append('id_app', data.id);
        await axios.post(
            process.env.REACT_APP_BASE_URL + 'api/application/' + room.id + '/clean',
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }


    const generatePicture = (data) => {
        const formData = new FormData();
        formData.append('id_app', data.id);
        axios.post(
            process.env.REACT_APP_BASE_URL + 'api/application/' + room.id + '/picture',
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
    }

    const changeQuestion = async () => {
        const previousQuestion = question;
        const previousBlob = blob;


        setBlobs([...blobs, blob]);
        setBlob(null)
        setRecordedChunks([]);
        setCounterDisplayQuestion(room.time_question);
        setCounterRecordingResponse(room.time_response);
        setCounterTampon(1);
        setQuestion(question + 1);
        setQuestionStep((questionStep + 1));
        setTries(2);
        setStopBeforeEnd(false);

        // uplodad previous question
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let mime = "webm";
        if (isSafari) {
            mime = "mp4";
        }

        const data = JSON.parse(localStorage.getItem('application_' + room.id));

        const idVideo = previousQuestion - 1;
        if (0 === idVideo) {
            await cleanData(data);

            generatePicture(data);
        }

        const formData = new FormData();
        formData.append('id_app', data.id);
        formData.append('id_file', idVideo);
        formData.append('mime', mime);
        formData.append('file', previousBlob, 'blob_' + idVideo + '.' + mime);

        const requests = await axios.post(
            process.env.REACT_APP_BASE_URL + 'api/application/' + room.id + '/blob',
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        );
    }

    /**
     * J'arrive à la dernière question. J'envoi le dernier blob
     * quand blobs.length = blobsSent.length c'est bon
     */
    const finalize = async() => {
        setFinalLoader(true);
        setBlobs([...blobs, blob]);

        // envoi de la dernière vidéo
        const previousQuestion = question;
        const previousBlob = blob;

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let mime = "webm";
        if (isSafari) {
            mime = "mp4";
        }

        const data = JSON.parse(localStorage.getItem('application_' + room.id));
        const idVideo = previousQuestion - 1;

        const formData = new FormData();
        formData.append('id_app', data.id);
        formData.append('id_file', idVideo);
        formData.append('mime', mime);
        formData.append('file', previousBlob, 'blob_' + idVideo + '.' + mime);

        const requests = await axios.post(
            process.env.REACT_APP_BASE_URL + 'api/application/' + room.id + '/blob',
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
        );


        const intervalId = setInterval(async () => {
            const dataId = JSON.parse(localStorage.getItem('application_' + room.id));
            const formData = new FormData();
            formData.append('id_app', dataId.id);

            const checkBlobs = await axios.post(
                process.env.REACT_APP_BASE_URL + 'api/application/' + room.id + '/check_blobs',
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
            );

            if (checkBlobs.data.count === room.questions.length) {
                setFinalLoader(false);
                clearInterval(intervalId);
                setFinalStep(true);
            }
        }, 2000);
    }

    const handleStartCapture = useCallback(() => {
        setIsRecording(true);

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let mime = "video/webm";
        if (isSafari) {
            mime = "video/mp4";
        }

        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: mime,
        });

        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );

        mediaRecorderRef.current.start();
    }, [webcamRef, setIsRecording, mediaRecorderRef]);

    const handleDataAvailable = useCallback(({ data }) => {
        if (data.size > 0) {
            setRecordedChunks((prev) => prev.concat(data));
        }
    }, [setRecordedChunks]);

    const handleStopCapture = useCallback(() => {
        if (!isRecording) {
            return;
        }

        clearTimeout(timeoutResponse);

        mediaRecorderRef.current.stop();
        setIsRecording(false);
        setCounterRecordingResponse(0);

    }, [mediaRecorderRef, isRecording, setIsRecording, setCounterRecordingResponse]);

    const handleDownload = useCallback(() => {
        if (recordedChunks.length) {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            let mime = "video/webm";
            if (isSafari) {
                mime = "video/mp4";
            }

            const blob = new Blob(recordedChunks, {
                type: mime
            });

            setBlob(blob);
        }
    }, [recordedChunks]);

    questionStep > 0 && counterDisplayQuestion > 0 && setTimeout(() => setCounterDisplayQuestion(counterDisplayQuestion - 1), 1000);

    0 === counterDisplayQuestion && counterTampon > 0 && setTimeout(() => setCounterTampon(counterTampon - 1), 1000);

    if (!displayWebcam && 0 === counterDisplayQuestion && 0 === counterTampon && null === blob) {
        setDisplayWebcam(true);
    }

    if (displayWebcam && counterRecordingResponse > 0) {
        if (!isRecording) {
            setCounterRecordingResponse(room.time_response);
            handleStartCapture();
        }

        timeoutResponse = setTimeout(() => setCounterRecordingResponse(counterRecordingResponse - 1), 1000);
    }

    if (0 === counterRecordingResponse) {
        clearTimeout(timeoutResponse);
        handleStopCapture();
    }

    if (!isRecording && null === blob ) {
        if (recordedChunks.length > 0) {
            handleDownload();
        }
    }

    if (blob && displayWebcam) {
        setDisplayWebcam(false);
    }

    if (stopBeforeEnd) {
        handleStopCapture();
    }

    if (isRecording && null == blob) {
        document.addEventListener('keyup', event => {
            if (false === stopBeforeEnd) {
                if (event.code === 'Space') {
                    setStopBeforeEnd(true);
                }
            }
        })
    }

    if (finalStep) {
        return <FinalStep room={room} blobs={blobs} setActualStep={setActualStep} />;
    }

    return (
        <>
        <LoaderFinal show={finalLoader}  />

        <div
            className="bg-black w-full h-[calc(100vh-120px)] bg-no-repeat bg-cover"
        >
            <div className="mx-auto max-w-7xl">
                <div className="flex gap-4">
                    <div className="bg-black w-full text-center pt-6">
                        <Title
                            room={room}
                            questionStep={questionStep}
                            displayWebcam={displayWebcam}
                            blob={blob}
                        />

                        <div className="w-full bg-black relative h-[45vh] max-h-[600px]">
                            <div className="relative h-full">
                                <CenterContent
                                    room={room}
                                    questionStep={questionStep}
                                    question={question}
                                    counterDisplayQuestion={counterDisplayQuestion}
                                    counterTampon={counterTampon}
                                    blob={blob}
                                />

                                <div className={displayWebcam ? '' : 'hidden'}>
                                    <p className="text-center text-white h-full w-full pt-40 absolute top-0 left-0">
                                        Pour continuer, vous devez accepter que<br />
                                        WhyMe accède pour l'enregistrement : <br /><br />
                                        - à votre caméra<br />
                                        - à votre micro
                                    </p>
                                    <div className="relative w-full flex justify-center">
                                        <WebcamComponent webcamRef={webcamRef} />
                                        <img src={RecIcon} className="absolute top-0 h-[60px] w-[40px] -ml-[660px]" alt="Timer" />
                                        <img src={ChronoIcon} className="absolute top-[65px] h-[45px] w-[40px] -ml-[660px]" alt="Rec" />
                                        <div className="absolute top-[76px] h-[25px] w-[25px] -ml-[663px] text-white font-bold text-xl">
                                            {counterRecordingResponse >= 0 ? counterRecordingResponse : 0}
                                        </div>
                                    </div>
                                </div>

                                <Button
                                    room={room}
                                    question={question}
                                    questionStep={questionStep}
                                    setQuestionStep={setQuestionStep}
                                    blob={blob}
                                    tries={tries}
                                    tryAgain={tryAgain}
                                    changeQuestion={changeQuestion}
                                    finalize={finalize}
                                    isRecording={isRecording}
                                    setStopBeforeEnd={setStopBeforeEnd}
                                    finalLoader={finalLoader}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterSite transparent={true} />
        </>
    );
}

export default Step6;
