import FooterSite from "../../components/footerSite";
import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";

const bg_webcam = require('../../assets/bg_webcam.jpg');

const Step4 = ({ room }) => {
    window.scrollTo(0, 0);

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('application_'+room.id) === null) {
            navigate(`/room/${room.id}/form`);
        }
    }, [])

    return (
        <>
        <div
            className="bg-black w-full h-[calc(100vh-120px)] bg-no-repeat bg-cover"
            style={{backgroundImage: `url(${bg_webcam})`}}
        >
            <div
                className="mx-auto max-w-7xl"
            >
                <div className="flex gap-4">
                    <div className="bg-white w-full text-center pt-16">
                        <h3 className="text-2xl	font-bold mb-4">
                            Avant de démarrer, quelques conseils :
                        </h3>

                        <p>
                            1 - Pas la peine de réviser, les questions posées font appel à votre personnalité.
                        </p>
                        <p>
                            2 - En moins de 10 secondes donnez votre avis et enregistrez-vous.
                        </p>
                        <p>
                            3 - Vous avez jusqu’à 3 essais pour chaque question.
                        </p>

                        <Link
                            to={`/room/${room.id}/record`}
                            state={{ actualStep: 5 }}
                        >
                            <button
                                className="w-full md:w-auto mt-16 mb-8 px-3 py-2 md:px-32 text-lg font-semibold shadow-sm bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme border border-white hover:border-pink-whyme cursor-pointer"
                            >
                                Je fais un test
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <FooterSite room={room} short={true} />
        </>
);
}

export default Step4;
