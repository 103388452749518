import {memo} from "react";
import Webcam from "react-webcam";

const WebcamComponent = memo(function Test({ webcamRef }) {
    const videoConstraints = {
        width: 720,
        height: 720,
        facingMode: "user"
    };

    return (
        <Webcam
            className="max-w-[600px] max-h-[600px] h-[60vh] w-[60vh]"
            audio={true}
            muted={true}
            imageSmoothing={true}
            mirrored={true}
            ref={webcamRef}
            videoConstraints={videoConstraints}
        />
    );
});

export default WebcamComponent;
