import {Link} from "react-router-dom";

const MenuTop = ({ room, setActualStep, actualMenu }) => {

    if (0 === actualMenu) {
        return (
            <nav className="mx-auto flex max-w-7xl items-center justify-center gap-x-6 p-2">
                <div className="flex gap-x-12">
                    <button className="cursor-pointer text-sm font-semibold leading-6 text-gray-900">
                        Description du poste
                    </button>
                    <button className="cursor-pointer text-sm font-semibold leading-6 text-gray-500 hover:text-gray-900">
                        Je postule
                    </button>
                </div>
            </nav>
        )
    }

    if (3 === actualMenu) {
        return <nav className="mx-auto flex max-w-7xl items-center justify-center gap-x-6 p-6" />;
    }

    return (
        <nav className="mx-auto flex max-w-7xl items-center justify-center gap-x-6 p-2">
            <div className="flex gap-x-12">
                <Link
                    to={`/room/${room.id}`}
                    state={{ actualStep: 1 }}
                >
                    <button className={1 === actualMenu ? `cursor-pointer text-sm font-semibold leading-6 text-gray-900` : `cursor-pointer text-sm font-semibold leading-6 text-gray-500 hover:text-gray-900`}>
                        Description du poste
                    </button>
                </Link>
                <Link
                    to={`/room/${room.id}/form`}
                    state={{ actualStep: 2 }}
                >
                    <button className={2 === actualMenu ? `cursor-pointer text-sm font-semibold leading-6 text-gray-900` : `cursor-pointer text-sm font-semibold leading-6 text-gray-500 hover:text-gray-900`}>
                        Je postule
                    </button>
                </Link>

            </div>
        </nav>
    )
}

export default MenuTop;
