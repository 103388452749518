import {useEffect, useState} from "react";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import axios from "axios";
import HeaderMain from "../components/header_main";
import Step1 from "./room/step1";
import Step2 from "./room/step2";
import Step3 from "./room/step3";
import Step4 from "./room/step4";
import StepLoader from "./room/stepLoader";
import Step5WebcamTest from "./room/step5_webcam_test";
import Step6 from "./room/step6";

const Room = ({ actualStepRouter, force }) => {
    const params = useParams();
    const [queryParams] = useSearchParams();
    const [room, setRoom] = useState(null);
    const [actualStep, setActualStep] = useState(actualStepRouter);
    const [, setOptimal] = useState({width: 720, height: 720});
    const [fileCv, setFileCv] = useState(null);
    const [offer, setOffer] = useState(null);

    useEffect(() => {
        if (!room) {
            axios
                .get(process.env.REACT_APP_BASE_URL + 'api/room/' + params.roomId)
                .then((response) => {
                    setRoom(response.data);
                });
        } else {
            document.title = room.title;
            document.description = room.teaser;
        }
    }, [room, params.roomId])

    // gestion du tag dans l'url de type "offre"
    const offerQueryUrl = queryParams.get('offre');
    if (offerQueryUrl && !offer) {
        setOffer(offerQueryUrl);
    }

    if (actualStep <= 5 && force && actualStepRouter !== actualStep) {
        setActualStep(actualStepRouter);
    } else if (actualStep > 5) {
        if (force && actualStep <= actualStepRouter) {
            setActualStep(actualStepRouter);
        } else {
            if (6 === actualStep && actualStepRouter < 5) {
                setActualStep(actualStepRouter);
            }
        }
    }

    if (!room) {
        return (
            <>
                <HeaderMain logoUrl={require('../assets/logo_whyme.jpg')} />
                <StepLoader />;
            </>
        )
    }

    let stepRender = null;

    if (room.id === "cc4fdbae-d8a7-4d03-af18-41f5a225e49b") {
        if (1 === actualStep) {
            stepRender = <Step2 room={room} actualStep={actualStep} setActualStep={setActualStep} />;
            window.scrollTo(0, 0);
        }
    } else {
        if (1 === actualStep) {
            stepRender = <Step1 room={room} setActualStep={setActualStep} actualStep={actualStep} offer={offer} />;
            window.scrollTo(0, 0);
        }
    }

    if (2 === actualStep) {
        stepRender = <Step2 room={room} actualStep={actualStep} setActualStep={setActualStep} fileCv={fileCv} setFileCv={setFileCv} offer={offer} />;
        window.scrollTo(0, 0);
    } else if (3 === actualStep) {
        stepRender = <Step3 room={room} actualStep={actualStep} setActualStep={setActualStep} />;
        window.scrollTo(0, 0);
    } else if (4 === actualStep) {
        stepRender = <Step4 room={room} actualStep={actualStep} setActualStep={setActualStep} />;
        window.scrollTo(0, 0);
    } else if (5 === actualStep) {
        stepRender = <Step5WebcamTest room={room} actualStep={actualStep} setActualStep={setActualStep} setOptimal={setOptimal} />;
        window.scrollTo(0, 0);
    } else if (6 === actualStep) {
        stepRender = <Step6 room={room} setActualStep={setActualStep} />
        window.scrollTo(0, 0);
    }

    return (
        <>
            <HeaderMain logoUrl={room.logo_url} />
            { stepRender }
        </>
    );
}

export default Room;