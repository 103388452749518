const Button = ({ question, questionStep, setQuestionStep, room, blob, tries, tryAgain, changeQuestion, finalize, isRecording, setStopBeforeEnd, finalLoader }) => {
    if (finalLoader) {
        return (
            <button
                className="w-full md:w-auto mt-4 mb-4 px-3 py-2 md:px-32 text-lg font-semibold shadow-sm bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme cursor-pointer"
            >
                Chargement ...
            </button>
        )
    }

    if (0 === questionStep) {
        return (
            <button
                className="w-full md:w-auto mt-16 mb-8 px-3 py-3 md:px-32 text-lg font-semibold shadow-sm bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme cursor-pointer"
                onClick={() => setQuestionStep(question)}
            >
                J'affiche la question {question}
            </button>
        );
    }

    if (null === blob && isRecording) {
        return (
            <button
                className="w-full md:w-auto mt-4 mb-4 px-3 py-2 md:px-32 text-lg font-semibold shadow-sm bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme cursor-pointer"
                onClick={() => setStopBeforeEnd(true)}
            >
                Vous avez terminé ?<br />
                Appuyez sur la barre espace
            </button>
        )
    }

    if (null !== blob) {
        return (
            <>
                {question + 1 <= room.questions.length ?
                    <button
                        className="w-full md:w-auto mt-4 mb-4 px-3 py-2 md:px-32 text-lg font-semibold shadow-sm bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme cursor-pointer"
                        onClick={changeQuestion}
                    >
                        Je passe à la question {question + 1}
                    </button>
                    :
                    <button
                        className="w-full md:w-auto mt-4 mb-4 px-3 py-2 md:px-32 text-lg font-semibold shadow-sm bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme cursor-pointer"
                        onClick={finalize}
                    >
                        Je finalise ma candidature
                    </button>
                }
                {tries > 0 ?
                    <p>
                        <button
                            className="text-white"
                            onClick={tryAgain}
                        >
                            > <span
                            className="underline hover:no-underline">Réenregistrer ({tries} {tries === 1 ? 'essai' : 'essais'})</span>
                        </button>
                    </p>
                    :
                    <></>
                }
            </>
        );
    }

    return null;
}

export default Button;
