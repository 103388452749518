const encartLeftLoader = () => {
    return (
        <div className="bg-white w-5/5 md:w-2/5 p-8">
            <h3 className="text-5xl	font-bold [word-spacing:0px] md:[word-spacing:100px]">
                <div role="status" className="max-w-sm animate-pulse">
                    <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-64 mb-4"></div>
                    <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-64 mb-4"></div>
                    <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-4"></div>
                    <span className="sr-only">Loading...</span>
                </div>
            </h3>

            <table className="table-fixed w-full">
                <tbody>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Type de contrat</td>
                    <td className="pt-2 pb-2">
                        <div role="status" className="max-w-sm animate-pulse mt-2">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Ville</td>
                    <td className="pt-2 pb-2">
                        <div role="status" className="max-w-sm animate-pulse mt-2">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Salaire</td>
                    <td className="pt-2 pb-2">
                        <div role="status" className="max-w-sm animate-pulse mt-2">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Date</td>
                    <td className="pt-2 pb-2">
                        <div role="status" className="max-w-sm animate-pulse mt-2">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Expérience</td>
                    <td className="pt-2 pb-2">
                        <div role="status" className="max-w-sm animate-pulse mt-2">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Télétravail</td>
                    <td className="pt-2 pb-2">
                        <div role="status" className="max-w-sm animate-pulse mt-2">
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

export default encartLeftLoader;
