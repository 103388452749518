import { useEffect, useState } from "react";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";

import HeaderSub from "../../components/header_sub";
import banner1 from "../../assets/banner_1.jpg";
import banner1Webp from "../../assets/banner_1.webp";
import icons from "../../assets/icons.png";
import upload from "../../assets/upload.png";
import MenuTop from "../../components/menu_top";
import EncartLeft from "../../components/encart_left";

import {isMobile, isTablet} from 'react-device-detect';
import FooterSite from "../../components/footerSite";
import Politics from "../../components/politics";
import AlreadyExist from "../../components/alreadyExist";
import {useNavigate} from "react-router-dom";
import AlertMobile from "../../components/alertMobile";

const Step2 = ({ room, setActualStep, actualStep, fileCv, setFileCv, offer }) => {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [errors, setErrors] = useState(null);
    const [file, setFile] = useState(null);
    const [fileFromServer, setFileFromServer] = useState(false);
    const [legalsCheck, setLegalsCheck] = useState(false);
    const [showLegals, setShowLegals] = useState(false);
    const [disable, setDisable] = useState(false);
    const [showAlreadyExist, setShowAlreadyExist] = useState(false);
    const [showMobileAlert, setShowMobileAlert] = useState(false);

    const [dropActive, setDropActive] = useState(false);

    const uploadFileTypes = ["PDF", "DOC", "DOCX"];

    const navigate = useNavigate();

    useEffect(() => {
        if (fileCv) {
            setFile(fileCv);
        }
    })

    useEffect(() => {
        if (!localStorage.getItem('application_'+room.id)) {
            localStorage.setItem('application_'+room.id, JSON.stringify({
                'room': room.id,
                'firstname': firstname,
                'lastname': lastname,
                'email': email,
                'phone': phone,
                'file': file,
                'linkedin': linkedin
            }));
        }
    })

    useEffect(() => {

        const queryParameters = new URLSearchParams(window.location.search)
        const dataId = queryParameters.get("dataId")

        if (dataId) {
            localStorage.removeItem('application_'+room.id);

            axios.get(process.env.REACT_APP_BASE_URL + 'api/application/' + dataId)
                .then(response => {
                    const data = response.data;

                    setFirstname(data.firstname.charAt(0).toUpperCase() + data.firstname.slice(1));
                    setLastname(data.lastname.charAt(0).toUpperCase() + data.lastname.slice(1));
                    setEmail(data.email);
                    setPhone(data.phone);
                    setLinkedin(data.linkedin);
                    setFileFromServer(true);
                    setFile(data.cv);

                    localStorage.setItem('application_'+room.id, JSON.stringify({
                        'room': room.id,
                        'id': dataId,
                        'firstname': firstname,
                        'lastname': lastname,
                        'email': email,
                        'phone': phone,
                        'file': file,
                        'linkedin': linkedin
                    }));

                })
                .catch();
        } else {
            if (localStorage.getItem('application_'+room.id)) {
                const data = JSON.parse(localStorage.getItem('application_'+room.id));

                setFirstname(data.firstname.charAt(0).toUpperCase() + data.firstname.slice(1));
                setLastname(data.lastname.charAt(0).toUpperCase() + data.lastname.slice(1));
                setEmail(data.email);
                setPhone(data.phone);
                setLinkedin(data.linkedin);
            }
        }
    }, []);

    const handleChangeLegals = () => {
        setLegalsCheck(!legalsCheck);
    }

    const closeLegals = (event) => {
        setShowLegals(false);
    }

    const closeAlreadyShow = (event) => {
        setShowAlreadyExist(false);
    }

    const closeMobile = () => {
        setShowMobileAlert(false);
    }

    const changeFirstname = (event) => {
        const value = event.target.value;
        const sanitizedValue = value.replace(/[/.,~=;:+?£*()°!@"'#$%&_0123456789]/gi, ''); // Regex to remove special characters

        localStorage.setItem('application_'+room.id, JSON.stringify({
            'room': room.id,
            'firstname': sanitizedValue,
            'lastname': lastname,
            'email': email,
            'phone': phone,
            'file': file,
            'linkedin': linkedin
        }));
        setFirstname(sanitizedValue);
    }

    const changeLastname = (event) => {
        const value = event.target.value;
        const sanitizedValue = value.replace(/[/.,~=;:+?£*()°!@"'#$%&_0123456789]/gi, ''); // Regex to remove special characters

        localStorage.setItem('application_'+room.id, JSON.stringify({
            'room': room.id,
            'firstname': firstname,
            'lastname': sanitizedValue,
            'email': email,
            'phone': phone,
            'file': file,
            'linkedin': linkedin
        }));
        setLastname(sanitizedValue);
    }

    const changePhone = (event) => {
        const value = event.target.value;
        const sanitizedValue = value.replace(/[/.,~=;:\-?£*()°!@"'#$%&_abcdefghijklmnopqrstuvwxyzéàèëäù]/gi, ''); // Regex to remove special characters

        localStorage.setItem('application_'+room.id, JSON.stringify({
            'room': room.id,
            'firstname': firstname,
            'lastname': lastname,
            'email': email,
            'phone': sanitizedValue,
            'file': file,
            'linkedin': linkedin
        }));
        setPhone(sanitizedValue);
    }

    const changeEmail = (event) => {
        const value = event.target.value;

        localStorage.setItem('application_'+room.id, JSON.stringify({
            'room': room.id,
            'firstname': firstname,
            'lastname': lastname,
            'email': value,
            'phone': phone,
            'file': file,
            'linkedin': linkedin
        }));
        setEmail(value);
    }

    const changeLinkedin = (event) => {
        const value = event.target.value;

        localStorage.setItem('application_'+room.id, JSON.stringify({
            'room': room.id,
            'firstname': firstname,
            'lastname': lastname,
            'email': email,
            'phone': phone,
            'file': file,
            'linkedin': value
        }));
        setLinkedin(value);
    }

    const submitForm = async (event) => {

        event.preventDefault();
        event.target.reportValidity();

        if (disable) {
            return false;
        }

        setErrors(null);
        setDisable(true);

        if (!firstname || !lastname || !email || !phone) {
            setErrors("Tous les champs doivent être renseignés.");
            setDisable(false);
            return;
        }

        if (!legalsCheck) {
            setErrors("Vous devez accepter la politique de confidentialité.");
            setDisable(false);
            return;
        }

        if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            setErrors("Votre email doit être dans un format valide.");
            setDisable(false);
            return;
        }

        if (!fileFromServer && !file && room.id !== "cc4fdbae-d8a7-4d03-af18-41f5a225e49b") {
            setErrors("Vous devez déposer votre CV.");
            setDisable(false);
            return;
        }

        if (phone.length < 10 || phone.length > 12) {
            setErrors("Votre numéro de téléphone doit avoir entre 10 et 12 caractères.");
            setDisable(false);
            return;
        }

        const formData = new FormData();
        if (typeof file !== 'string') {
            formData.append("file", file);
        }
        formData.append("firstname", firstname.charAt(0).toUpperCase() + firstname.slice(1));
        formData.append("lastname", lastname.charAt(0).toUpperCase() + lastname.slice(1));
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("linkedin", linkedin);
        if (fileFromServer) {
            formData.append('fileFromServer', 1);
        } else {
            formData.append('fileFromServer', 0);
        }


        if (isMobile || isTablet) {
            formData.append("mobile", 1);
        } else {
            formData.append("mobile", 0);
        }

        if (localStorage.getItem('application_'+room.id)) {
            const data = JSON.parse(localStorage.getItem('application_'+room.id));

            if (data.id) {
                formData.append('id', data.id);
            }
        }

        if (offer) {
            formData.append('offer', offer);
        }

        try {
            if (isMobile || isTablet) {
                axios.post(
                    process.env.REACT_APP_BASE_URL + 'api/application/' + room.id,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    })
                    .then(function (response) {})
                    .catch(function (error) {});

                setDisable(false);
                setShowMobileAlert(true);

                return false;
            }

            try {
                const postApi = await axios.post(
                    process.env.REACT_APP_BASE_URL + 'api/application/' + room.id,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    });

                if (room.id === "cc4fdbae-d8a7-4d03-af18-41f5a225e49b") {
                    localStorage.setItem('application_'+room.id, JSON.stringify({
                        'room': room.id,
                        'id': postApi.data.id,
                        'firstname': firstname.charAt(0).toUpperCase() + firstname.slice(1),
                        'lastname': lastname.charAt(0).toUpperCase() + lastname.slice(1),
                        'email': email,
                        'phone': phone,
                        'file': 'null',
                        'linkedin': linkedin,
                    }));
                } else {
                    localStorage.setItem('application_'+room.id, JSON.stringify({
                        'room': room.id,
                        'id': postApi.data.id,
                        'firstname': firstname.charAt(0).toUpperCase() + firstname.slice(1),
                        'lastname': lastname.charAt(0).toUpperCase() + lastname.slice(1),
                        'email': email,
                        'phone': phone,
                        'file': typeof file !== 'string' ? file.name : file,
                        'linkedin': linkedin
                    }));
                }

                localStorage.setItem('firstname_' + room.id, firstname);


                setDisable(false);

                navigate(`/room/${room.id}/recap`, { actualStep: 3 });

                setActualStep(3);
            } catch (err) {
                if (409 === err.response.status) {
                    setShowAlreadyExist(true);
                    setDisable(false);

                    return false;
                }

                setDisable(false);
                return false;
            }




        } catch (err) {
            console.error(err);
        }

        return false;
    }

    const uploadHandleChange = (fileUpload) => {
        setFileFromServer(false);
        setErrors("");
        setFile(fileUpload);
        setFileCv(fileUpload);
    }

    const uploadErrorSize = (file) => {
        setErrors("Votre CV ne doit pas dépasser les 2mo.");
    }

    let disabledButton = false;
    let buttonClass = 'cursor-not-allowed bg-gray-whyme text-white';
    if (firstname && lastname && email && phone && legalsCheck && !disable) {
        disabledButton = false;
        buttonClass = 'bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme border border-white hover:border-pink-whyme cursor-pointer';
    }

    const dropHandler = (e) => {
        e.preventDefault();
        setDropActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (e.dataTransfer.files[0].size > 2000000) {
                setErrors("Votre CV ne doit pas dépasser les 2mo.");
            } else {
                setErrors("");
                setFile(e.dataTransfer.files[0]);
            }
        }
    }

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDropActive(true);
        } else if (e.type === "dragleave") {
            setDropActive(false);
        }
    }

    return (
        <div onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={dropHandler}>
            {dropActive ?
                <div className="fixed top-0 left-0 w-full h-full block bg-black bg-opacity-60 z-50 flex text-center items-center">
                    <p className="text-white text-3xl text-center block w-full">Et on relâche !</p>
                </div>
                :
                <></>
            }

            {showMobileAlert ? <AlertMobile closeMobile={closeMobile} /> : <></>}

            <HeaderSub bannerWebp={banner1Webp} banner={banner1}/>

            <MenuTop room={room} setActualStep={setActualStep} actualMenu={2} actualStep={actualStep} />

            <div className="mx-auto max-w-7xl mb-8">
                <div className="flex flex-col md:flex-row items-start gap-4">
                    <EncartLeft room={room} noButton={true} />

                    <div className="bg-white w-5/5 md:w-3/5 p-8">
                        <form onSubmit={submitForm}>
                            <h3 className="text-2xl	font-bold mb-4">
                                Saisissez vos informations personnelles,<br />puis postulez en vidéo en moins de 2 minutes.
                            </h3>

                            { errors ?
                                <div className="bg-red-100 border border-red-400 text-red-700 mb-4 px-4 py-3 rounded relative" role="alert">
                                    <span className="block sm:inline">{ errors }</span>
                                </div>
                                :
                                null
                            }

                            <div className="grid max-w-2xl grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-6 md:col-span-2">
                                <div className="sm:col-span-3">
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="firstname"
                                            id="firstname"
                                            placeholder="* Votre prénom"
                                            autoComplete="given-name"
                                            className="block w-full border p-2.5 shadow-sm border-gray-300 placeholder:text-black focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-pink-whyme text-lg leading-6"
                                            value={firstname}
                                            onChange={changeFirstname}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <div className="md:mt-2">
                                        <input
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            placeholder="* Votre nom"
                                            autoComplete="family-name"
                                            className="block w-full border p-2.5 shadow-sm border-gray-300 placeholder:text-black focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-pink-whyme text-lg leading-6"
                                            value={lastname}
                                            onChange={changeLastname}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <div>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="* Votre email"
                                            autoComplete="email"
                                            className="block w-full border p-2.5 shadow-sm border-gray-300 placeholder:text-black focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-pink-whyme text-lg leading-6"
                                            value={email}
                                            onChange={changeEmail}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <div>
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            placeholder="* Votre téléphone"
                                            autoComplete="phone"
                                            className="block w-full border p-2.5 shadow-sm border-gray-300 placeholder:text-black focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-pink-whyme text-lg leading-6"
                                            value={phone}
                                            onChange={changePhone}
                                        />
                                    </div>
                                </div>
                            </div>

                            {room.id === "cc4fdbae-d8a7-4d03-af18-41f5a225e49b" ? <></> :
                                <>
                                    <h3 className="mt-8 text-2xl font-bold mb-4">
                                        Déposez votre CV
                                    </h3>

                                    <div className="w-full">
                                        <FileUploader
                                            handleChange={uploadHandleChange}
                                            multiple={false}
                                            maxSize={2}
                                            onSizeError={uploadErrorSize}
                                            name="file"
                                            types={uploadFileTypes}
                                            hoverTitle=" "
                                            children={
                                                <div className="w-full cursor-pointer">
                                                    <div
                                                        className="mt-2 flex justify-center border border-gray-900/25 w-full">
                                                        <div className="text-center w-full">
                                                            {file ?
                                                                <div
                                                                    className="mt-4  p-6 flex flex-col md:flex-row text-sm leading-6">
                                                                    <p className="text-base flex flex-row items-center">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                             fill="none"
                                                                             viewBox="0 0 24 24" strokeWidth={1.5}
                                                                             stroke="currentColor"
                                                                             className="w-24 h-24">
                                                                            <path strokeLinecap="round"
                                                                                  strokeLinejoin="round"
                                                                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
                                                                        </svg>
                                                                        {file.name ?
                                                                            <strong
                                                                                className="break-all">{file.name}</strong>
                                                                            :
                                                                            <strong
                                                                                className="break-all">{file}</strong>
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        <button
                                                                            className="mt-6 ml-16 px-16 py-2 text-lg font-semibold shadow-sm bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme border border-white hover:border-pink-whyme cursor-pointer"
                                                                        >
                                                                            Modifier
                                                                        </button>
                                                                    </p>
                                                                </div>
                                                                :
                                                                <div className="mt-4 flex flex-col text-sm p-6">
                                                                    <p className="text-base">
                                                                        Faites glisser votre fichier n’importe où dans la zone pour l’importer<br /><br />
                                                                    </p>
                                                                    <p className="flex flex-col md:flex-row justify-between items-center">
                                                                        <img src={icons} className="w-[130px] h-[90px]" />
                                                                        <img src={upload} className="w-[130px] h-[90px]"  />
                                                                        <button
                                                                            className="h-[60px] mt-[15px] px-16 py-2 text-lg font-semibold shadow-sm bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme border border-white hover:border-pink-whyme cursor-pointer"
                                                                        >
                                                                            Parcourir
                                                                        </button>
                                                                    </p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </div>

                                    <div className="w-full mt-4">
                                        <div className="sm:col-span-6">
                                            <div>
                                                <input
                                                    type="text"
                                                    name="linkedin"
                                                    id="linkedin"
                                                    placeholder="Saisissez votre profil Linkedin"
                                                    className="block w-full text-lg border p-2.5 shadow-sm border-gray-300 placeholder:text-black focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-pink-whyme text-lg leading-6"
                                                    value={linkedin}
                                                    onChange={changeLinkedin}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </>
                            }

                            <div className="w-full mt-4">
                                <div className="flex items-center mb-4">
                                    <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        value=""
                                        onChange={handleChangeLegals}
                                        className="w-4 h-4 min-w-[20px] text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                                    />
                                        <label htmlFor="default-checkbox"  className="ml-2 text-sm font-medium text-gray-900">
                                            J’accepte la <button type="button" onClick={() => setShowLegals(true)} className="underline">politique de confidentialité</button> décrivant la finalité des traitements de mes données personnelles.
                                        </label>
                                </div>
                            </div>

                            {showLegals ? <Politics closePolitics={closeLegals} /> : null}

                            {showAlreadyExist ? <AlreadyExist close={closeAlreadyShow} /> : null}

                            <h3 className="text-2xl	font-bold mb-4 mt-8">
                                Réalisez votre interview spontanée en moins de 2 minutes.
                            </h3>

                            <input
                                type="submit"
                                className={`w-full md:w-auto mt-2 mb-32 px-3 py-2 md:px-32 text-lg font-semibold shadow-sm ${buttonClass}`}
                                value="Je réponds"
                                disabled={disabledButton}
                             />
                        </form>
                    </div>
                </div>
            </div>

            <FooterSite room={room} />
        </div>
    )
}

export default Step2;
