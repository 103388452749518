import HeaderSub from "../../components/header_sub";
import banner1 from "../../assets/banner_1.jpg";
import MenuTop from "../../components/menu_top";
import EncartLeftLoader from "../../components/encart_left_loader";

const StepLoader = ({ setActualStep }) => {


    return (
        <>
            <HeaderSub banner={banner1}/>

            <MenuTop setActualStep={setActualStep} actualMenu={0} />

            <div className="mx-auto max-w-7xl mb-8">
                <div className="flex flex-col md:flex-row items-start gap-4">
                    <EncartLeftLoader />

                    <div className="bg-white w-5/5 md:w-3/5 p-8">
                        <h3 className="text-2xl	font-bold mb-4">Découvrez le job</h3>

                        <div className="video-responsive">
                            <div
                                role="status"
                                className="status w-[249px] h-[250px] flex items-center justify-center bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
                            >
                                <svg className="w-12 h-12 text-gray-200 dark:text-gray-600"
                                     xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor"
                                     viewBox="0 0 384 512">
                                    <path
                                        d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z"/>
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>

                        <div role="status" className="max-w-sm animate-pulse mb-4 ml-[280px]">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                            <span className="sr-only">Loading...</span>
                        </div>

                        <p className="mb-4 font-bold">Vous voulez en savoir plus, lancez cette interview (1min).</p>

                        <div className="parser-html">
                            <div role="status" className="max-w-sm animate-pulse">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                                <div
                                    className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                                <div
                                    className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                                <div
                                    className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                                <span className="sr-only">Loading...</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StepLoader;
