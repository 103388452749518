import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import ErrorPage from './pages/error-pages';
import Room from './pages/room';

import './assets/fonts/Helvetica.ttf';
import './assets/fonts/Helvetica-Bold.ttf';
import './index.css';

const Home = () => {
    return <div></div>;
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/room/:roomId',
        element: <Room actualStepRouter={1} force={true} />,
    },
    {
        path: '/room/:roomId/form',
        element: <Room actualStepRouter={2} force={true} />,
    },
    {
        path: '/room/:roomId/recap',
        element: <Room actualStepRouter={3} force={true} />,
    },
    {
        path: '/room/:roomId/test',
        element: <Room actualStepRouter={4} force={true} />,
    },
    {
        path: '/room/:roomId/record',
        element: <Room actualStepRouter={5} force={true} />,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

