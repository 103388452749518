import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const Legals = ({closeLegals}) => {
    const [open, setOpen] = useState(true)

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={() => closeLegals()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[1000px] sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                                            Mentions légales
                                        </Dialog.Title>
                                        <div className="mt-2 text-left">
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">1. ÉDITEUR DU SITE INTERNET</strong><br />

                                                Le Site internet « WhyMe » accessible à l’adresse URL « www.whyme.jobs » est édité par la société par actions simplifiée WHYNOT aucapital de 10 000,00 euros inscrite au registre du Commerce et des Sociétés de Nanterre sous le numéro 902 980 846 et dont le siège social est établi au 18 rue Léon Blum - 92220 Bagneuxet représentépar son président, M. Fabrice Bert.<br /><br />

                                                Contact : <a href="mailto:fabrice@whyme.jobs">fabrice@whyme.jobs</a>
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">2. HÉBERGEMENT DU SITE INTERNET</strong><br />

                                                Le site internet www.whyme.jobs est hébergé par la société Webflowdont le siège social est situé au 398 11th Street, 2nd Floor CA 94103 San Francisco
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">3. DIRECTEUR DE PUBLICATION</strong><br />

                                                Le directeur de la publication du Site internet est Monsieur Alexandre Fortis
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">4. DÉCLARATION À LA COMMISSION NATIONALE INFORMATIQUE ET LIBERTÉS</strong><br />

                                                Les traitements automatisés de données à caractère personnel ont fait l’objet d’une déclaration auprès de la Commission nationale de l’informatique et deslibertés sous le numéro n° (à venir)conformément à l’article 22 de la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et auxlibertés telle que modifiée en 2004.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-pink-whyme px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={closeLegals}
                                    >
                                        Fermer
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default Legals;
