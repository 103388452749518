import leftPersonShadow from "../assets/left_person_shadow.jpg";
import playerEnd from "../assets/player_end.png";
import logoWhyMe from "../assets/logo_whyme.png";
import {useRef, useState} from "react";

const FinalEncart = ({ room, load, videoFile }) => {
    const [showImg, setShowImg] = useState(true);

    const playerRef = useRef(null)

    const play = () => {
        if (showImg) {
            setShowImg(false);
            playerRef.current.play();
        }
    }

    return (
        <div className="bg-white w-5/5 md:w-2/5 relative">
            {load ?
                <>
                    <div className="absolute w-full pt-20">
                        <p className="text-center">
                            <svg width="80" height="80" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg"
                                 stroke="#E71D72" className="inline">
                                <g fill="none" fillRule="evenodd">
                                    <g transform="translate(1 1)" strokeWidth="2">
                                        <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                                        <path d="M36 18c0-9.94-8.06-18-18-18">
                                            <animateTransform
                                                attributeName="transform"
                                                type="rotate"
                                                from="0 18 18"
                                                to="360 18 18"
                                                dur="1s"
                                                repeatCount="indefinite"/>
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </p>
                        <p className="text-center mt-8 text-pink-whyme">
                            Chargement de votre vidéo en cours ...
                        </p>
                    </div>
                    <picture className="w-full">
                        <source srcSet={ leftPersonShadow } type="image/jpeg" />
                        <img src={ leftPersonShadow } alt="Person" fetchpriority="high" />
                    </picture>
                </>
                :
                <div className="relative">
                    <video
                        ref={playerRef}
                        className="w-full h-[500px]"
                        controls
                        disablePictureInPicture
                        controlsList="nodownload nofullscreen"
                        playsInline
                        src={videoFile}
                    >
                    </video>
                    {showImg ?
                        <>
                            <div className="w-full h-full block bg-transparent absolute top-0 left-0 cursor-pointer" onClick={play}></div>
                            <img src={playerEnd} onClick={play} alt="play" className="absolute bottom-20 right-[10%] w-[80%] cursor-pointer" />
                        </>
                        :
                        <></>
                    }


                </div>
            }


            <div className="py-8 px-16">
                <h3 className="text-5xl	font-bold [word-spacing:0px] md:[word-spacing:100px]">{ room.title }</h3>
            </div>
        </div>
    );
}

export default FinalEncart;
