import leftPerson from "../assets/left_person.jpg";
import leftPersonWebp from "../assets/left_person.webp";

const encartLeft2 = ({ room, setActualStep, noButton }) => {
    return (
        <div className="bg-white w-5/5 md:w-2/5">
            <picture className="w-full">
                <source srcSet={ leftPersonWebp } type="image/webp" />
                <source srcSet={ leftPerson } type="image/jpeg" />
                <img src={ leftPerson } alt="Person" fetchpriority="high" />
            </picture>
            <div className="py-8 px-16">
                <h3 className="text-5xl	font-bold [word-spacing:0px] md:[word-spacing:100px]">{ room.title }</h3>
            </div>
        </div>
    );
}

export default encartLeft2;
