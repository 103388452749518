import Parser from 'html-react-parser';
import HeaderSub from "../../components/header_sub";
import banner1 from "../../assets/banner_1.jpg";
import banner1Webp from "../../assets/banner_1.webp";
import MenuTop from "../../components/menu_top";
import EncartLeft from "../../components/encart_left";
import {useState} from "react";
import Player from "../../components/svg/player";
import FooterSite from "../../components/footerSite";

const Step1 = ({ room, setActualStep, actualStep }) => {
    const [youtubeIframe, setYoutubeIframe] = useState(false);

    const launchVideo = () => {
        setYoutubeIframe(true);
    }

    let youtubeFrame = <></>;
    let txtVideo = <></>;
    if (room.video_url) {

        if (!youtubeIframe) {
            youtubeFrame = (
                <div className="video-responsive w-[250px] flex lg:w-auto lg:block">
                    <div className="video" data-videoid="kOF_gRLYXOY">
                        <button className="relative image overflow-hidden" onClick={launchVideo}>
                            <img
                                src={`https://img.youtube.com/vi/${room.video_url}/sddefault.jpg`}
                                className="w-[340px] max-w-[340px] -ml-[43px] h-full"
                                alt={room.title}
                                loading="lazy"
                            />
                            <Player />
                        </button>
                    </div>
                </div>
            );
        } else {
            youtubeFrame = (
                <div className="video-responsive w-full flex lg:w-auto lg:block">
                    <iframe
                        width="249"
                        height="250px"
                        src={`https://www.youtube.com/embed/${room.video_url}?autoplay=1&controls=0&rel=0&showinfo=0`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="Embedded youtube"
                    />
                </div>
            );
        }

        txtVideo = <p className="mb-4 font-bold">Vous voulez en savoir plus, lancez cette interview (1min).</p>
    }

    return (
        <>
            <HeaderSub bannerWebp={banner1Webp} banner={banner1} />

            <MenuTop room={room} setActualStep={setActualStep} actualMenu={1} actualStep={actualStep} />

            <div className="mx-auto max-w-7xl">
                <div className="flex flex-col md:flex-row items-start gap-4">
                    <EncartLeft room={room} setActualStep={setActualStep} actualStep={actualStep} />

                    <div className="bg-white w-5/5 md:w-3/5 p-8">
                        <h3 className="text-2xl	font-bold mb-4">Découvrez le job</h3>
                        { youtubeFrame }
                        <p className="mb-4">{ room.teaser }</p>
                        { txtVideo }
                        <div className="parser-html">{ Parser(room.description) }</div>
                    </div>
                </div>
            </div>

            <FooterSite room={room} />
        </>
    )
}

export default Step1;
