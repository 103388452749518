import {useEffect, useState} from "react";
import Webcam from "react-webcam";
import FooterSite from "../../components/footerSite";
import {useNavigate} from "react-router-dom";

const silhouette = require('../../assets/silouhette.png');
const bg_webcam = require('../../assets/bg_webcam.jpg');


const Step5WebcamTest = ({ room, setActualStep, setOptimal }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('application_'+room.id) === null) {
            navigate(`/room/${room.id}/form`);
        }
    }, [])

    const [authorization, setAuthorization] = useState(false);
    const videoConstraints = {
        width: 720,
        height: 720,
        facingMode: "user"
    };

    const ResolutionsToCheck = [
        {width: 160, height: 160},
        {width: 320, height: 320},
        {width: 640, height: 640},
        {width: 720, height: 720},
    ];

    let left = 0;
    let right = ResolutionsToCheck.length;
    let selectedWidth;
    let selectedHeight;
    let mid;

    const FindMaximum_WidthHeight_ForCamera = () => {
        if (left > right) {
            setOptimal({
                width: selectedWidth,
                height: selectedHeight
            });

            return false;
        }

        mid = Math.floor((left + right) / 2);

        const temporaryConstraints = {
            "audio": true,
            "video": {
                "mandatory": {
                    "minWidth": ResolutionsToCheck[mid].width,
                    "minHeight": ResolutionsToCheck[mid].height,
                    "maxWidth": ResolutionsToCheck[mid].width,
                    "maxHeight": ResolutionsToCheck[mid].height
                },
                "optional": []
            }
        }

        navigator.mediaDevices.getUserMedia(temporaryConstraints).then(checkSuccess).catch(checkError);
    }

    const checkSuccess = (stream) => {
        selectedWidth = ResolutionsToCheck[mid].width;
        selectedHeight = ResolutionsToCheck[mid].height;

        left = mid + 1;

        for (let track of stream.getTracks()) {
            track.stop()
        }

        FindMaximum_WidthHeight_ForCamera();
    }
    const checkError = () => {
        right = mid - 1;

        FindMaximum_WidthHeight_ForCamera();
    }

    const handleUserMedia = async (optimal) => {
        setAuthorization(true)

        FindMaximum_WidthHeight_ForCamera();
    }

    const handleUserMediaError = () => {
        console.log("Message d'erreur à afficher ?");
    }

    let disabledButton = false;
    let buttonClass = 'cursor-not-allowed bg-gray-whyme text-white';
    if (authorization) {
        disabledButton = false;
        buttonClass = 'bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme border border-white hover:border-pink-whyme cursor-pointer';
    }

    return (
        <>
        <div
            className="bg-black w-full h-[calc(100vh-120px)] bg-no-repeat bg-cover"
            style={{backgroundImage: `url(${bg_webcam})`}}
        >
            <div className="mx-auto max-w-7xl">
                <div className="flex gap-4">
                    <div className="bg-white w-full text-center pt-4">
                        <h3 className="text-2xl	font-bold mb-4">
                            Ajustez votre camera et le son de votre micro, puis essayez !
                        </h3>

                        <div className="w-full bg-black relative h-[70vh] max-h-[600px]">
                            <div className="relative h-full">
                                <p className="text-center text-white h-full w-full pt-40 absolute top-0 left-0">
                                    Pour continuer, vous devez accepter que<br />
                                    WhyMe accède pour l'enregistrement : <br /><br />
                                    - à votre caméra<br />
                                    - à votre micro
                                </p>
                                <div className="relative w-full flex justify-center">
                                    <Webcam
                                        audio={true}
                                        muted={true}
                                        imageSmoothing={true}
                                        mirrored={true}
                                        videoConstraints={videoConstraints}
                                        className="max-w-[600px] max-h-[600px] h-[70vh] w-[70vh]"
                                        onUserMedia={handleUserMedia}
                                        onUserMediaError={handleUserMediaError}
                                    />
                                    {authorization ?
                                        <img src={silhouette} alt="Cadrage" className="absolute h-full"/>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <button
                            className={`w-full md:w-auto mt-8 mb-8 px-3 py-2 md:px-32 text-lg font-semibold shadow-sm ${buttonClass}`}
                            disabled={disabledButton}
                            onClick={() => setActualStep(6)}
                        >
                            Je démarre
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <FooterSite room={room} short={true} />
        </>
    );
}

export default Step5WebcamTest;
