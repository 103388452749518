const HeaderSub = ({ banner, bannerWebp }) => {
    return (
        <div id="header_sub">
            <nav className="mx-auto flex justify-between max-w-7xl">
                <div className="flex lg:flex-1">
                    <picture className="banner">
                        <source srcSet={ bannerWebp } type="image/webp" />
                        <source srcSet={ banner } type="image/jpeg" />
                        <img src={ banner } alt="Banner" fetchpriority="high" />
                    </picture>
                </div>
            </nav>
        </div>
    )
}

export default HeaderSub;
