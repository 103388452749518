import {Link} from "react-router-dom";

const LinkedinShare = require('../assets/linkedin_share.png');

const encartLeft = ({ room, setActualStep, noButton, actualStep }) => {

    const shareLinkedin = () => {
        const url = process.env.REACT_APP_BASE_URL + "api/room/" + room.id + "/share";
        window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + url,  '', '_blank, width=500, height=500, resizable=yes, scrollbars=yes');

        return false;
    }

    return (
        <div className="bg-white w-5/5 md:w-2/5 p-8">
            <h3 className="text-5xl	font-bold [word-spacing:0px]">{ room.title }</h3>

            <table className="table-fixed w-full">
                <tbody>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Type de contrat</td>
                    <td className="pt-2 pb-2">{ room.contract_type}</td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Ville</td>
                    <td className="pt-2 pb-2">{ room.city }</td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Salaire</td>
                    <td className="pt-2 pb-2">{ room.salary }</td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Date</td>
                    <td className="pt-2 pb-2">{ room.date }</td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Expérience</td>
                    <td className="pt-2 pb-2">{ room.experience }</td>
                </tr>
                <tr className="border-b-2 border-black">
                    <td className="pt-2 pb-2">Télétravail</td>
                    <td className="pt-2 pb-2">{ room.remote }</td>
                </tr>
                </tbody>
            </table>

            {noButton ? null :
                <>
                <Link
                    to={`/room/${room.id}/form`}
                    state={{ actualStep: 2 }}
                >
                    <button
                        className="mt-16 w-full bg-pink-whyme hover:bg-white px-3 py-2 text-lg font-semibold text-white hover:text-pink-whyme shadow-sm border border-white hover:border-pink-whyme"
                    >
                        Je postule
                    </button>
                </Link>

                <div className="mt-4 text-lg flex flex-row justify-between">
                    <div>Partager ce poste</div>
                    <div className="">
                        <a
                            href="#"
                            onClick={() => shareLinkedin()}
                        >
                            <img className="w-[20px] h-[20px]" src={LinkedinShare} alt="Partage Linkedin" />
                        </a>
                    </div>
                </div>
                </>
            }
        </div>
    );
}

export default encartLeft;
