import {useState} from "react";
import LinkedinShare from "../assets/linkedin_share.png";
import Legals from "./legals";
import Politics from "./politics";

const FooterSite = ({ room, short, transparent }) => {
    const [showLegals, setShowLegals] = useState(false);
    const [showPolitics, setShowPolitics] = useState(false);

    const closeLegals = () => {
        setShowLegals(false);
    }
    const closePolitics = () => {
        setShowPolitics(false);
    }

    const year = new Date().getFullYear();

    if (transparent) {
        return (
            <>
                {showLegals ? <Legals closeLegals={closeLegals} /> : null}
                {showPolitics ? <Politics closePolitics={closePolitics} /> : null}


                <footer className="bg-black text-center pt-4 pb-4 text-white text-sm">
                    Solution proposée par <a className="font-bold" href="https://whyme.jobs">Whyme.jobs</a> -
                    Copyright {year}
                </footer>
            </>
        );
    }

    if (short) {
        return (
            <>
                {showLegals ? <Legals closeLegals={closeLegals} /> : null}
                {showPolitics ? <Politics closePolitics={closePolitics} /> : null}

                <footer className="bg-zinc-100 mx-auto max-w-7xl text-center pt-4 pb-4 text-slate-600 text-sm">
                    Solution proposée par <a className="text-black" href="https://whyme.jobs">Whyme.jobs</a> -
                    Copyright {year}
                </footer>
            </>
        );
    }

    return (
        <>
            {showLegals ? <Legals closeLegals={closeLegals} /> : null}
            {showPolitics ? <Politics closePolitics={closePolitics} /> : null}

            <footer className="bg-zinc-100 mx-auto max-w-7xl text-center pt-4 pb-4 text-slate-600 text-sm">
                Solution proposée par <a className="text-black" href="https://whyme.jobs">Whyme.jobs</a> -
                Copyright {year} |
                &nbsp;<button onClick={() => setShowLegals(true)}>Mentions légales</button> |
                &nbsp;<button onClick={() => setShowPolitics(true)}>Politique de confidentialité</button> | <a href="https://www.linkedin.com/company/whyme-jobs/" target="_blank" className="align-middle inline-block"><img className="w-[15px] h-[15px]" src={LinkedinShare} alt="Partage Linkedin" /></a>
            </footer>
        </>
    );
}

export default FooterSite;
