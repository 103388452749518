import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const Politics = ({closePolitics}) => {
    const [open, setOpen] = useState(true)

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={() => closePolitics()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[1000px] sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                                            Politique de protection des données à caractère personnel
                                        </Dialog.Title>
                                        <div className="mt-2 text-left">
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">LA FINALITÉ DU TRAITEMENT</strong><br />
                                                Nos finalités sont déterminées, légitimes et explicites:<br />
                                                Elles sont compatibles avec nos missions. Les données collectées pour une finalité précise ne sont pas utilisées à d’autres fins. Nos finalités sont en adéquation avec les normes de conformité de la Cnil (Commission nationale de l’informatique et des libertés).<br />
                                                À titre d’exemple, les données collectées sur le candidat lors de l’inscription sur le site  <a href="https://recrutement.whyme.jobs" target="_blank">recrutement.whyme.jobs</a> sont les données nécessaires au bon déroulement du processus de recrutement. Avec l’objectif:<br />
                                                «De permettre à chaque candidat d’être contacté par des recruteurs cherchant à pourvoir des emplois;et in fine de trouver un emploi.»
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">LA PERTINENCE DES DONNÉES</strong><br />
                                                La finalité définie permet de déterminer la pertinence des données que nous allons collecter. Seules les données adéquates et strictement nécessaires pour atteindre la finalité seront collectées et traitées. Ainsi nous ne collectons que les données permettant:<br />
                                                • au candidat de valoriser sa candidature<br />
                                                • au candidat d’exprimer ses préférences<br />
                                                • au candidat de décrire son parcours<br />
                                                • au recruteur d’évaluer le candidat<br />
                                                • au recruteur de trouver les candidats correspondant à ses critères de recrutement<br />
                                                • d’éviter les usurpations d’identité
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                Quelles données collectons-nous?<br />
                                                Pour le candidat:<br />
                                                • Nom/Prénom<br />
                                                • Mail<br />
                                                • Téléphone<br />
                                                • Réseaux sociaux (Linkedin)<br />
                                                • CV<br />
                                                • Une interview dans un format vidéo<br />
                                                • Éventuellement d’autres informations renseignées dans les champs libres
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">LA CONSERVATION LIMITÉE DES DONNÉES</strong><br />
                                                La finalité permet également de fixer la durée de conservation des données conformément aux exigences de la Cnil.<br />
                                                Les données de candidature sont mises à disposition aux recruteurs à partir de la date de la candidature pour une durée définit par l'entreprise dans laquelle vous postulez et au maximum pour une durée de 2 ans. Les profils des candidats qui souhaitent figurer dans la CVthèque sont présentés aux recruteurs pendant la durée définis par l'entreprise sur son formulaire.
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">L’INFORMATION ET LES DROITS DES PERSONNES</strong><br />
                                                Suivant cette politique, chaque document de collecte de données est conforme à la loi informatique et libertés n° 78-17 du 6 janvier 1978 modifiée, et informe la personne auprès de laquelle sont recueillies des données à caractère personnel:<br />
                                                • de l’identité du responsable du traitement et, le cas échéant, de celle de son représentant;<br />
                                                • de la finalité poursuivie par le traitement auquel les données sont destinées;<br />
                                                • du caractère obligatoire ou facultatif de ses réponses;<br />
                                                • des conséquences éventuelles, à son égard, d’un défaut de réponse;<br />
                                                • des destinataires ou catégories de destinataires des données;<br />
                                                • du droit des personnes à l’égard des traitements des données à caractère personnel<br />
                                                • de l’existence, le cas échéant, de transferts de données à caractère personnel envisagés à destination d’un État non membre de la Communauté européenne;<br />
                                                • de la durée de conservation des catégories de données traitées<br />
                                                Conformément à la loi informatique et libertés, l’exercice de vos droits d’accès, d’interrogation, de modification, d’opposition et de rectification aux informations s’effectue par un courrier adressé à l’entreprise
                                                WhyNot<br />
                                                « Comité sécurité et Protection des données »<br />
                                                18 rue Léon Blum - 92220 Bagneux<br />
                                                Ou par courrier électronique àhello@whyme.jpobsavec l’objet: «ACCES DONNEES PERSONNELLES». Vos demandes doivent être accompagnées d’une photocopie d’un titre d’identité avec votre signature.
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">ACCÈS RESTREINT AUX DONNÉES</strong><br />
                                                Seuls les destinataires dûment habilités par WhyMe peuvent accéder, dans le cadre d’une politique de sécurité permettant notamment la gestion des accès aux seules informations nécessaires à l’activité. Les droits d’accès sont accordés selon les principes du « moindre privilège » et du « besoin d’en savoir ». Les droits d’accès sont accordés en adéquation avec la fonction de l’utilisateur et sont mis à jour en cas d’évolution ou de changement de fonction. Ainsi, la politique de protection des données personnelles de WhyMe s’organise autour de mesures d’ordre logique, physique ou organisationnel.
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">LES TRANSFERTS DE DONNÉES</strong><br />
                                                L’ensemble de vos données sont hébergées en Union Européenne.<br />
                                                Le Comité sécurité et protection des données a désigné depuis mai 2018 un délégué à la protection des données (DPO). Ses missions consistent à assurer la conformité et la sécurité des traitements de données à caractère personnel. Le DPO du Groupe est situé au 18 rue Léon Blum 92220 Bagneux. La présente politique formalisée de protection des données à caractère personnel de WhyMe, à destination des collaborateurs de l’entreprise et des personnes extérieures concernées par les traitements, est accessible et disponible en interne auprès des collaborateurs, et en externe via les sites internet, les contrats et les documents commerciaux. Cette politique sera réexaminée et mise à jour chaque année.
                                            </p>
                                            <p className="text-sm text-gray-500 mt-4">
                                                <strong className="text-gray-700 font-bold">LES COOKIES</strong><br />
                                                Chez WhyMe nous utilisions des outils pour exploiter, gérer et faciliter le suivi de notre site.<br /><br />

                                                Ces outils utilisent des cookies et d'autres technologies de suivi. Ces outils sont créés et gérés par des tiers en dehors de notre contrôle. À ce titre, WhyMe n'est pas responsable des informations saisies par ces tiers ni de la manière dont ces tiers utilisent et protègent ces informations.
                                                <br /><br />
                                                Nous utilisons des cookies et autres technologies de suivi que si vous nous en donnez l'autorisation en cliquant sur "Accepter “ en arrivant sur le site. Vous pouvez gérer vos autorisations à tout moment en cliquant sur les préférences.
                                                <br /><br />
                                                Nous partageons ou divulguons vos informations personnelles avec les prestataires de services tiers suivants :<br />
                                                • Google Analytics, qui est l’outil d'analyse du trafic web proposé par Google. C’est un instrument puissant et largement utilisé, qui permet aux propriétaires de sites web d'avoir une vision approfondie et en temps réel sur la manière dont leurs sites sont utilisés, par combien d'internautes et par qui. Cela donne un aperçu des performances du domaine et permet de l'optimiser (<a href="https://marketingplatform.google.com/about/analytics/terms/fr/" target="_blank">cgv Google Analytics</a>).<br />
                                                • Cookies publicitaires: Ces cookies sont utilisés pour rendre les messages publicitaires plus pertinents pour vous. Ils remplissent des fonctions telles qu'empêcher la même publicité de réapparaître en permanence, garantir que les publicités sont correctement affichées pour les annonceurs et, dans certains cas, sélectionner des publicités basées sur vos intérêts. Nous utilisons entre autres la plateforme Google Ads :https://policies.google.com/technologies/partner-sites
                                                <br /><br />
                                                • Hotjar, est une plateforme d'analyse de l'expérience utilisateur (UX) et de feedback des utilisateurs. Elle fournit des outils pour aider les propriétaires de sites web à comprendre comment les visiteurs interagissent avec leur site, en enregistrant des sessions de navigation et en recueillant des commentaires. (<a href="https://www.hotjar.com/legal/policies/acceptable-use/" target="_blank">https://www.hotjar.com/legal/policies/acceptable-use/</a>)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-pink-whyme px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={closePolitics}
                                    >
                                        Fermer
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default Politics;
