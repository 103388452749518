import {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";

const LoaderFinal = ({ show }) => {
    return <Transition.Root show={show} as={Fragment} onClose={() => {return null}}>
        <Dialog as="div" className="relative z-50">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[1000px] sm:p-6">
                            <div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-xl text-pink-whyme">
                                        Encore quelques instants <br />on compile toutes les réponses
                                    </Dialog.Title>
                                    <div className="mt-8">
                                        <p className="text-center">
                                        <svg width="80" height="80" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#E71D72" className="inline">
                                            <g fill="none" fillRule="evenodd">
                                                <g transform="translate(1 1)" strokeWidth="2">
                                                    <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                                                    <path d="M36 18c0-9.94-8.06-18-18-18">
                                                        <animateTransform
                                                            attributeName="transform"
                                                            type="rotate"
                                                            from="0 18 18"
                                                            to="360 18 18"
                                                            dur="1s"
                                                            repeatCount="indefinite"/>
                                                    </path>
                                                </g>
                                            </g>
                                        </svg>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>
}

export default LoaderFinal;