const CenterContent = ({ room, questionStep, counterDisplayQuestion, counterTampon, blob }) => {

    if (0 === questionStep) {
        return (
            <div className="text-white font-bold text-xl pt-36">
                <p>
                    Cliquez sur le bouton pour afficher la question.
                </p>

                <p className="mt-12">
                    {room.time_question} secondes pour y réfléchir et {room.time_response} autres secondes pour y répondre.
                </p>
            </div>
        );
    }

    if (counterDisplayQuestion > 0) {
        return (
            <>
                <p className="text-pink-whyme relative z-50">
                    Question {questionStep} : {room.time_question} secondes pour y réfléchir...<br />
                    + {room.time_response} (autres) secondes pour y répondre.
                </p>
                <div className="relative w-full flex justify-center flex-col h-[60vh] max-h-[600px]">
                    <img className="w-full" src={`${process.env.REACT_APP_BASE_URL}/videos/questions/${room.id}/${questionStep}.png`} alt="Question" />

                    <p className="mb-8 text-7xl text-white">
                        {counterDisplayQuestion}
                    </p>
                </div>
            </>
        )
    }

    if (counterTampon > 0) {
        return (
            <>
                <p className="text-pink-whyme relative z-50">
                    Question {questionStep} : {room.time_question} secondes pour y réfléchir...<br />
                    + {room.time_response} (autres) secondes pour y répondre.
                </p>
                <div className="relative w-full flex justify-center flex-col h-[60vh] max-h-[600px]">
                    <img className="w-full" src={`${process.env.REACT_APP_BASE_URL}/videos/questions/${room.id}/${questionStep}.png`} alt="Question" />

                    <p className="mb-8 text-7xl text-pink-whyme">
                        C'est à vous !
                    </p>
                </div>
            </>
        )
    }

    if (null !== blob) {
        return (
            <div className="relative w-full flex justify-center">
                <video
                    id="video-replay"
                    className="max-w-[600px] max-h-[600px] w-[65vh] h-[65vh]"
                    height="600"
                    width="600"
                    controls
                    controlsList="nodownload nofullscreen"
                    disablePictureInPicture
                    src={URL.createObjectURL(blob)}
                />
            </div>
        );
    }

    return null;
}

export default CenterContent;