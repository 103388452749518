const logoWhyMe = require('../assets/logo_whyme.jpg');
const logoWhyMeWebp = require('../assets/logo_whyme.webp');

const HeaderMain = ({ logoUrl }) => {


    return (
        <>
        <header className="bg-white fixed w-full z-50">
            <nav className="mx-auto max-w-7xl flex justify-between pb-2 pt-2">
                <div className="flex lg:flex-1">
                    {logoUrl ? <img className="fullheader:-ml-32 h-16 w-auto" src={`${process.env.REACT_APP_BASE_URL}${logoUrl}`} alt="Logo client" /> : null}
                </div>

                <div className="lg:flex lg:flex-1 lg:justify-end fullheader:-mr-32">
                    <picture>
                        <source className="h-16 w-auto" srcSet={ logoWhyMeWebp } type="image/webp" />
                        <source className="h-16 w-auto" srcSet={ logoWhyMe } type="image/jpeg" />
                        <img className="h-16 w-auto" src={ logoWhyMe } alt="Logo WhyMe" />
                    </picture>
                </div>
            </nav>
        </header>
        <div className="h-[75px] w-full"></div>
        </>
    )
}

export default HeaderMain;
